/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import theme, { styled } from "../theme/theme"
import { GridThemeProvider, BaseCSS } from "styled-bootstrap-grid"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from '../theme/global';
import Helmet from "react-helmet"
import { PrismicNavigation } from "../prismic/PrismicNavigation"
import Footer from "../components/Footer/Footer"
import { Col, Container, Row } from "../components/Grid/Grid"
import { mb, mt } from "../utils/spacing"

const StyledContainer = styled(Container)`
  ${mt(5)};
  ${mb(7)};
`

const Layout = ({ children }) =>
  (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={theme}>
        <React.Fragment>
          <GlobalStyles/>
          <Helmet titleTemplate={`Page not found`}/>
          <BaseCSS/>
          <PrismicNavigation/>
          <StyledContainer>
            <Row>
              <Col col={12}>
          <h1>Page Not Found</h1>
          <p>Sorry we couldn't find the page you were looking for.</p>
              </Col>
            </Row>
          </StyledContainer>
          <Footer/>
        </React.Fragment>
      </GridThemeProvider>
    </ThemeProvider>
  )

export default Layout
